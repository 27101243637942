import { createSlice } from "@reduxjs/toolkit"

// utils
import axios from "../../utils/axios"


import constants from "../../utils/constants"
//
import { dispatch } from "../store"

import mock_flows from "../../utils/flowMocks/mock_flows"
//import mock_flows from "src/utils/flowMocks/presentation_flows"
import mock_stats from "../../utils/flowMocks/mock_stats"

// ----------------------------------------------------------------------



const initialState = {
    isLoading: false,
    error: null,
    flows: [],
}

const slice = createSlice({
    name: "flows",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET FLOWS
        getFlowsSuccess(state, action) {
            state.isLoading = false

            const augmentedFlows = []
            // prepare data for parsing
            if (action.payload && action.payload.length) {
                for (let i = 0; i < action.payload.length; i += 1) {
                    augmentedFlows.push(action.payload[i])
                    augmentedFlows[i].flowPointsTemp = {}
                    const flowPoints = action.payload[i].flowPoints

                    for (let j = 0; j < flowPoints.length; j += 1) {
                        let issuesKeys = {}
                        if (flowPoints[j].issues) {
                            issuesKeys = Object.keys(flowPoints[j].issues)
                            for (let k = 0; k < issuesKeys.length; k += 1) {
                                if (flowPoints[j].issues[issuesKeys[k]].leadsTo) {
                                    const leadsTo = Object.keys(flowPoints[j].issues[issuesKeys[k]].leadsTo)
                                    for (let x = 0; x < leadsTo.length; x += 1) {
                                        if (leadsTo[x] === constants.defaultNodeTypes.flowpoint) {
                                            flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]],
                                             
                                                dest: constants.defaultNodeTypes.flowpoint,
                                                destId: `${augmentedFlows[i].id}_flowPoint${j + 1}`,
                                                id: `${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`,
                                                parent: issuesKeys[k],
                                            }
                                        } else if (leadsTo[x] === constants.defaultNodeTypes.external) {
                                            flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]],
                                            
                                                dest: "external",
                                                destId: `${augmentedFlows[i].id}_external${j + 1}`,
                                                id: `${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`,
                                                parent: issuesKeys[k],
                                            }
                                        } else if (leadsTo[x] === constants.defaultNodeTypes.internal) {
                                            flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]],
                                            
                                            }
                                        } else continue

                                        if (leadsTo[x] === constants.defaultNodeTypes.flowpoint) {
                                            flowPoints[j].issues[issuesKeys[k]].occurencesFP =
                                                flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]].occurences
                                            flowPoints[j].issues[issuesKeys[k]].occurencesOT =
                                                flowPoints[j].issues[issuesKeys[k]].occurences -
                                                flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]].occurences
                                        }
                                        flowPoints[j].issues[issuesKeys[k]].leadsTo[`${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`] =
                                            { ...flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]] }
                                        delete flowPoints[j].issues[issuesKeys[k]].leadsTo[leadsTo[x]]
                                    }
                                }

                            }
                        }


                        let emotionKeys = {}
                        if (flowPoints[j].emotions) {
                            emotionKeys = Object.keys(flowPoints[j].emotions)
                            for (let k = 0; k < emotionKeys.length; k += 1) {
                                if (flowPoints[j].emotions[emotionKeys[k]].leadsTo) {
                                    const leadsTo = Object.keys(flowPoints[j].emotions[emotionKeys[k]].leadsTo)
                                    for (let x = 0; x < leadsTo.length; x += 1) {
                                        if (leadsTo[x] === constants.defaultNodeTypes.flowpoint) {
                                            flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]],
                                                dest: constants.defaultNodeTypes.flowpoint,
                                                destId: `${augmentedFlows[i].id}_flowPoint${j + 1}`,
                                                id: `${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`,
                                                parent: emotionKeys[k],
                                            }
                                        } else if (leadsTo[x] === constants.defaultNodeTypes.external) {
                                            flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]],
                                                dest: "external",
                                                destId: `${augmentedFlows[i].id}_external${j + 1}`,
                                                id: `${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`,
                                                parent: emotionKeys[k],
                                            }
                                        } else if (leadsTo[x] === constants.defaultNodeTypes.internal) {
                                            flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]] = {
                                                ...flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]],
                                            }
                                        } else continue

                                        if (leadsTo[x] === constants.defaultNodeTypes.flowpoint) {
                                            flowPoints[j].emotions[emotionKeys[k]].occurencesFP =
                                                flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]].occurences
                                            flowPoints[j].emotions[emotionKeys[k]].occurencesOT =
                                                flowPoints[j].emotions[emotionKeys[k]].occurences -
                                                flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]].occurences
                                        }
                                        flowPoints[j].emotions[emotionKeys[k]].leadsTo[`${action.payload[i].id}_${leadsTo[x]}${j}_${k}_${x}`] =
                                            { ...flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]] }
                                        delete flowPoints[j].emotions[emotionKeys[k]].leadsTo[leadsTo[x]]
                                    }
                                }

                            }
                        }

              
                        augmentedFlows[i].flowPointsTemp[`${augmentedFlows[i].id}_flowPoint${j}`] = {
                            ...flowPoints[j],
                            parent: flowPoints[j - 1] ? `${augmentedFlows[i].id}_flowPoint${j - 1}` : null,
                            next: flowPoints[j + 1] ? `${augmentedFlows[i].id}_flowPoint${j + 1}` : null,
                        }
                    }
                    augmentedFlows[i].flowPoints = { ...augmentedFlows[i].flowPointsTemp }
                    delete augmentedFlows[i].flowPointsTemp
                }
            }

            state.flows = augmentedFlows
        },
    },
})


// Reducer
export default slice.reducer


// Actions

// ----------------------------------------------------------------------

export function getFlows(clientId, callback) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = {
                data: mock_stats(mock_flows)
            }
            // LOAD FLOW FROM JSON
            dispatch(slice.actions.getFlowsSuccess(response.data))
            if (callback) callback(response.data)
            return response.data
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

