// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------


export const IS_STAGING = process.env.NEXT_PUBLIC_REACT_APP_ENV === "staging"
export const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_REACT_APP_ENV === "development"
export const IS_PROD = process.env.NEXT_PUBLIC_REACT_APP_ENV === "production"
export const IS_LOCAL = process.env.NEXT_PUBLIC_REACT_APP_ENV === "local"

let hostApi = process.env.REACT_APP_HOST_API || "https://sdk.flowpoint.ai/api/v1"
let dashboardUrl = process.env.REACT_APP_HOST_API || "https://dashboard.flowpoint.ai"
let fpScript = "https://staging-sdk.flowpoint.ai?apiKey=t_6d4c78700ebc1d396ffa0d6b990a58d5&clientId=c4iyK8Zyc3tWyhQkpyH4mZlENJckvI"
let gtagScript = ""

if (IS_LOCAL) {
    hostApi = "http://localhost:5000/api/v1"
    dashboardUrl = "https://staging-dashboard.flowpoint.ai"
    fpScript = "http://localhost:5000?apiKey=t_48febf066a3ad352aac6f4a3e3d76cd2&clientId=Uu00BsxpbFhoZdHuU6sW7P9zEGGO03"
}

if (IS_DEVELOPMENT) {
    hostApi = "https://staging-sdk.flowpoint.ai/api/v1"
    dashboardUrl = "https://staging-dashboard.flowpoint.ai"
}

if (IS_STAGING) {
    hostApi = "https://staging-sdk.flowpoint.ai/api/v1"
    dashboardUrl = "https://staging-dashboard.flowpoint.ai"
}

if (IS_PROD) {
    hostApi = "https://sdk.flowpoint.ai/api/v1"
    dashboardUrl = "https://dashboard.flowpoint.ai"
    fpScript = "https://sdk.flowpoint.ai?apiKey=t_4b03db1e8ce3d5129e9e300b6ba59cd3&clientId=2lkkEc1bajUo5A26i8kuMdGw6J2Dd7"
    gtagScript = "https://www.googletagmanager.com/gtag/js?id=AW-11014059734"
}


export const HOST_API = hostApi
export const DASHBOARD_URL = dashboardUrl
export const FP_SCRIPT = fpScript
export const GTAG_SCRIPT = gtagScript

export const FIREBASE_API = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------


export const HEADER = {
  MOBILE_HEIGHT: 60,
  MOBILE_HEIGHT_MAIN: 60,
  MAIN_DESKTOP_HEIGHT: 88,
  MAIN_DESKTOP_HEIGHT_MAIN: 70,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
