export default {
    DISPLAY_ISSUE_NO_DEFAULT: 3,
    AGGREGATED_ISSUES_EXPAND_FACTOR: 2,
    edgeColors: {
        red: "#FF4842",
        lightRed: "#FFE8E8",
        lightOrange: "#FFF0DA",
        orange: "#FF9700",
        yellow: "#FFD600",
        lightYellow: "#FFFFF0",
        green: "#40C700",
        blue: "#3333FF",
        black: "black",
        white: "white",
    },
    textColors: {
        grey: "#212B36",
    },
    edgeTypes: {
        totalEdge: "totalEdge",
        directEdge: "directEdge",
        warningEdge: "warningEdge",
        issueEdge: "issueEdge",
        bounceWarningEdge: "bounceWarningEdge",
    },
    nodeTypes: {
        flowNode: "flowNode",
        technicalIssueNode: "technicalIssueNode",
        behaviouralIssueNode: "behaviouralIssueNode",
        emotionNode: "emotionNode",
        otherFlowNode: "otherFlowNode",
        dummyNode: "dummyNode",
        aggregatedNode: "aggregatedNode",
        insightsNode: 'insightsNode'
    },
    handleType: {
        bottom: "a",
        mid: "b",
        upper: "c",
        bottom_center: "bottom_side",
        top_center: "top_center",
    },
    handleMethod: {
        target: "target",
        source: "source",
    },
    handlePosition: {
        bottom: "bottom",
        mid: "mid",
        left: "left",
        right: "right",
        top: 'top',
    },
    defaultNodeTypes: {
        flowpoint: "flowpoint",
        external: "external",
        internal: "internal",
        page_reload: "page_reload",
    },
    edgeStyle: {
        step: false,
    },
}
