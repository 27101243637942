


export default
    [
        {
            "id": "fded5d8f8f19bc57775adc1552aaacd7c70deebd0a8a075cb0d43eeadcbd2c4f",
            "metadata": {
                "name": "Add product"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "match",
                        "value": "/product",
                        "valuePretty": "/product"
                    },
                    "name": "Product page",
                    "emotions": {
                        "confusion": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 900
                                        },
                                        "/login": {
                                            "occurences": 100
                                        },
                                        "/register": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 1100
                                },
                                "occurences": 1100,
                                "flowpoint": {
                                    "occurences": 400
                                }
                            },
                            "occurences": 1500
                        },
                        "frustration": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 900
                                        },
                                        "/login": {
                                            "occurences": 100
                                        },
                                        "/register": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 1100
                                },
                                "occurences": 1100,
                                "flowpoint": {
                                    "occurences": 400
                                }
                            },
                            "occurences": 1500
                        },
                    },
                    "issues": {
                        "focus_lost": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 900
                                        },
                                        "/login": {
                                            "occurences": 100
                                        },
                                        "/register": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 1100
                                },
                                "occurences": 1100,
                                "flowpoint": {
                                    "occurences": 400
                                }
                            },
                            "occurences": 1500
                        },
                        "page_close": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 600
                                        }
                                    },
                                    "occurences": 600
                                },
                                "occurences": 600
                            },
                            "occurences": 600
                        },
                        "page_reload": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 200
                                        }
                                    },
                                    "occurences": 200
                                },
                                "occurences": 200,
                                "flowpoint": {
                                    "occurences": 100
                                }
                            },
                            "occurences": 300
                        },
                        "b33f156bad511667e158f60938be5bd4": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 100
                                        },
                                        "/register": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 200
                                },
                                "occurences": 200
                            },
                            "occurences": 200
                        }
                    },
                    "leadsFrom": {
                        "path": {
                            "/reviews/*": {
                                "occurences": 400
                            },
                            "/": {
                                "occurences": 900
                            },
                            "/profile": {
                                "occurences": 200
                            },
                            "/explore": {
                                "occurences": 100
                            },
                            "/competition": {
                                "occurences": 100
                            }
                        }
                    },
                    "occurences": 1700
                },
                {
                    "type": "mouse_click",
                    "url": {
                        "type": "match",
                        "value": "/cart",
                        "valuePretty": "/cart"
                    },
                    "nodes": [
                        "a17f8485c078052e85ff7d30b0f74dd3"
                    ],
                    "name": "Add to Cart",
                    
                    "text": "ADD TO CART",
                    "issues": {
                        "focus_lost": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/reviews": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 100
                                },
                                "occurences": 100
                            },
                            "occurences": 100
                        },
                        "page_close": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/reviews": {
                                            "occurences": 100
                                        }
                                    },
                                    "occurences": 100
                                },
                                "occurences": 100
                            },
                            "occurences": 100
                        }
                    },
                    "leadsFrom": {
                        "path": {
                            "/": {
                                "occurences": 100
                            },
                            "/profile": {
                                "occurences": 100
                            },
                            "/reviews/*": {
                                "occurences": 200
                            }
                        }
                    },
                    "occurences": 400,
                    "direct": {
                        "occurences": 300
                    }
                },
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "match",
                        "value": "/checkout",
                        "valuePretty": "/checkout"
                    },
                    "name": "Checkout page",
                    "leadsFrom": {
                        "path": {
                            "/reviews": {
                                "occurences": 300
                            }
                        }
                    },
                    "leadsTo": {
                        "external": {
                            "occurences": 100
                        },
                        "internal": {
                            "dest": {
                                "/profile": {
                                    "occurences": 100
                                },
                                "/reviews/*": {
                                    "occurences": 100
                                }
                            },
                            "occurences": 200
                        }
                    },
                    "occurences": 300
                }
            ],
            "emotions": {
                "confusion": {
                    "type": "confusion",
                    "class": "emotion"
                },
                "frustration": {
                    "type": "frustration",
                    "class": "emotion"
                },
            },
            "issues": {
                "focus_lost": {
                 
                    "type": "focus_lost",
                    "class": "behaviour"
                },
                "page_close": {
              
                    "type": "page_close",
                    "class": "behaviour"
                },
                "page_reload": {
           
                    "class": "behaviour",
                    "type": "page_reload"
                },
                "b33f156bad511667e158f60938be5bd4": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "FATAL: THIS SHOULD NOT BE LOADED WHEN NOT DEBUGGING!"
                    ]
                }
            }
        },
        {
            "id": "f7ebfe32bd9b4a3a059b8825aa1ba7fd592f3b06f7a12e84d844c1885e8fecaf",
            "metadata": {
                "name": "See in shop"
            },
            "flowPoints": [
                {
                    "type": "mouse_click",
                    "nodes": [
                        "3000567fb87771bf048a05e1f68d6569",
                        "1ede198ca4fd232e6b619d5c2a1d0f5e"
                    ],
                    "name": "See in shop",
                    "text": "See in shop",
                    "leadsFrom": {
                        "path": {
                            "/explore": {
                                "occurences": 7
                            },
                            "/": {
                                "occurences": 2
                            },
                            "/profile/*": {
                                "occurences": 1
                            }
                        }
                    },
                    "leadsTo": {
                        "external": {
                            "occurences": 12
                        }
                    },
                    "occurences": 12
                }
            ]
        },
        {
            "id": "ed5c549db6e67aa21af8ca35656c3ee2a8688721e4a60f63b8a2b571600f4067",
            "metadata": {
                "name": "Homepage"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "match",
                        "value": "/",
                        "valuePretty": "/"
                    },
                    "name": "Homepage",
                    "leadsTo": {
                        "internal": {
                            "dest": {
                                "/explore": {
                                    "occurences": 223
                                },
                                "/competition": {
                                    "occurences": 3
                                },
                                "/rewards": {
                                    "occurences": 5
                                },
                                "/login": {
                                    "occurences": 29
                                },
                                "/reviews": {
                                    "occurences": 6
                                },
                                "/reviews/*": {
                                    "occurences": 15
                                },
                                "/profile/*": {
                                    "occurences": 2
                                },
                                "/profile": {
                                    "occurences": 3
                                },
                                "/register": {
                                    "occurences": 1
                                }
                            },
                            "occurences": 287
                        },
                        "external": {
                            "occurences": 139
                        }
                    },
                    "occurences": 426,
                    "leadsFrom": {
                        "path": {
                            "/register": {
                                "occurences": 5
                            },
                            "/explore": {
                                "occurences": 66
                            },
                            "/rewards": {
                                "occurences": 1
                            },
                            "/profile": {
                                "occurences": 1
                            },
                            "/faq": {
                                "occurences": 1
                            },
                            "/login": {
                                "occurences": 3
                            },
                            "/reviews/*": {
                                "occurences": 4
                            }
                        }
                    }
                }
            ]
        },
        {
            "id": "57f66e0cc86178347f53f4e492190b4704e394be055c9d1a8c8270d7106b24d1",
            "metadata": {
                "name": "Follow from profile page"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "regex",
                        "value": "/profile/[a-zA-Z0-9_:.!,%-]*$",
                        "valuePretty": "/profile/*"
                    },
                    "name": "User's profile page",
                    "issues": {
                        "focus_lost": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/register": {
                                            "occurences": 8
                                        },
                                        "/login": {
                                            "occurences": 3
                                        },
                                        "/explore": {
                                            "occurences": 7
                                        },
                                        "/profile": {
                                            "occurences": 6
                                        },
                                        "/reviews/*": {
                                            "occurences": 2
                                        },
                                        "/": {
                                            "occurences": 21
                                        },
                                        "/rewards": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 48
                                },
                                "occurences": 57,
                                "external": {
                                    "occurences": 9
                                }
                            },
                            "occurences": 57
                        },
                        "page_close": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "external": {
                                    "occurences": 6
                                },
                                "occurences": 20,
                                "internal": {
                                    "dest": {
                                        "/explore": {
                                            "occurences": 2
                                        },
                                        "/": {
                                            "occurences": 9
                                        },
                                        "/register": {
                                            "occurences": 1
                                        },
                                        "/login": {
                                            "occurences": 1
                                        },
                                        "/profile": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 14
                                }
                            },
                            "occurences": 20
                        },
                        "775f1fd50cf472568c52f87eaeaf733d": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 10
                                        },
                                        "/login": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 11
                                },
                                "occurences": 11
                            },
                            "occurences": 11
                        },
                        "64526a8a231320ca4ba813366f87ef9c": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 1
                                },
                                "occurences": 1
                            },
                            "occurences": 1
                        },
                        "page_reload": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 2
                                        }
                                    },
                                    "occurences": 2
                                },
                                "occurences": 2
                            },
                            "occurences": 2
                        },
                        "b33f156bad511667e158f60938be5bd4": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/register": {
                                            "occurences": 2
                                        },
                                        "/": {
                                            "occurences": 3
                                        },
                                        "/rewards": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 6
                                },
                                "occurences": 6
                            },
                            "occurences": 6
                        }
                    },
                    "occurences": 68,
                    "leadsFrom": {
                        "path": {
                            "/register": {
                                "occurences": 14
                            },
                            "/explore": {
                                "occurences": 12
                            },
                            "/login": {
                                "occurences": 4
                            },
                            "/": {
                                "occurences": 2
                            },
                            "/profile": {
                                "occurences": 6
                            },
                            "/reviews/*": {
                                "occurences": 3
                            }
                        }
                    }
                },
                {
                    "type": "mouse_click",
                    "nodes": [
                        "e147ec2320ac0b6a91ecb3195210ddfd"
                    ],
                    "name": "Follow",
                    "text": "Follow",
                    "url": {
                        "type": "regex",
                        "value": "/profile/[a-zA-Z0-9_:.!,%-]*$",
                        "valuePretty": "/profile/*"
                    }
                }
            ],
            "issues": {
                "focus_lost": {
                    "type": "focus_lost",
                    "class": "behaviour"
                },
                "page_close": {
                    "type": "page_close",
                    "class": "behaviour"
                },
                "775f1fd50cf472568c52f87eaeaf733d": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "A client-side exception has occurred, see here for more info: https://nextjs.org/docs/messages/client-side-exception-occurred"
                    ]
                },
                "64526a8a231320ca4ba813366f87ef9c": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "You are overriding current access token, that means some other app is expecting different access token and you will probably break things. Please consider passing access_token directly to API parameters instead of overriding the global settings."
                    ]
                },
                "page_reload": {
                    "class": "behaviour",
                    "type": "page_reload"
                },
                "b33f156bad511667e158f60938be5bd4": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "FATAL: THIS SHOULD NOT BE LOADED WHEN NOT DEBUGGING!"
                    ]
                }
            }
        },
        {
            "id": "a1bc9b9417e578b1a500e59265c4c3d606e3e673451a77ebb2159df8185652a1",
            "metadata": {
                "name": "Follow from review page"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "regex",
                        "value": "^/reviews/[a-zA-Z0-9_/?:.&!,%-]*$",
                        "valuePretty": "/reviews/*"
                    },
                    "name": "Review page",
                    "issues": {
                        "focus_lost": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 44
                                        },
                                        "/explore": {
                                            "occurences": 31
                                        },
                                        "/profile/*": {
                                            "occurences": 2
                                        },
                                        "/register": {
                                            "occurences": 1
                                        },
                                        "/login": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 79
                                },
                                "occurences": 127,
                                "external": {
                                    "occurences": 48
                                }
                            },
                            "occurences": 127
                        },
                        "page_close": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 32
                                        },
                                        "/explore": {
                                            "occurences": 16
                                        }
                                    },
                                    "occurences": 48
                                },
                                "occurences": 56,
                                "external": {
                                    "occurences": 8
                                }
                            },
                            "occurences": 56
                        },
                        "page_reload": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 3
                                        }
                                    },
                                    "occurences": 3
                                },
                                "occurences": 4,
                                "external": {
                                    "occurences": 1
                                }
                            },
                            "occurences": 4
                        },
                        "775f1fd50cf472568c52f87eaeaf733d": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 2
                                        }
                                    },
                                    "occurences": 2
                                },
                                "occurences": 2
                            },
                            "occurences": 2
                        },
                        "b33f156bad511667e158f60938be5bd4": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 3
                                        },
                                        "/explore": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 4
                                },
                                "occurences": 5,
                                "external": {
                                    "occurences": 1
                                }
                            },
                            "occurences": 5
                        }
                    },
                    "leadsFrom": {
                        "path": {
                            "/explore": {
                                "occurences": 84
                            },
                            "/competition": {
                                "occurences": 1
                            },
                            "/": {
                                "occurences": 15
                            },
                            "/profile/*": {
                                "occurences": 2
                            },
                            "/profile": {
                                "occurences": 1
                            },
                            "/login": {
                                "occurences": 1
                            }
                        }
                    },
                    "occurences": 160
                },
                {
                    "type": "mouse_click",
                    "nodes": [
                        "70efc324d9baa4792c99ddb87d992007"
                    ],
                    "name": "Follow",
                    "text": "Follow",
                    "url": {
                        "type": "regex",
                        "value": "^/reviews/[a-zA-Z0-9_/?:.&!,%-]*$",
                        "valuePretty": "/reviews/*"
                    }
                }
            ],
            "issues": {
                "focus_lost": {
                    "type": "focus_lost",
                    "class": "behaviour"
                },
                "page_close": {
                    "type": "page_close",
                    "class": "behaviour"
                },
                "page_reload": {
                    "class": "behaviour",
                    "type": "page_reload"
                },
                "775f1fd50cf472568c52f87eaeaf733d": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "A client-side exception has occurred, see here for more info: https://nextjs.org/docs/messages/client-side-exception-occurred"
                    ]
                },
                "b33f156bad511667e158f60938be5bd4": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "FATAL: THIS SHOULD NOT BE LOADED WHEN NOT DEBUGGING!"
                    ]
                }
            }
        },
        {
            "id": "7eea7c65bda1673724c54e837ed94df9e47911e3325ed15b533e35cc85d63969",
            "metadata": {
                "name": "Claim reward"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "match",
                        "value": "/rewards",
                        "valuePretty": "/rewards"
                    },
                    "name": "User's profile page",
                    "issues": {
                        "focus_lost": {
                            "insights":[
                                {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
                                {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
                                {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
                            ],
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 7
                                        },
                                        "/rewards": {
                                            "occurences": 17
                                        },
                                        "/login": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 25
                                },
                                "occurences": 25
                            },
                            "occurences": 25
                        },
                        "page_close": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 5
                                        },
                                        "/rewards": {
                                            "occurences": 9
                                        }
                                    },
                                    "occurences": 14
                                },
                                "occurences": 14
                            },
                            "occurences": 14
                        },
                        "b33f156bad511667e158f60938be5bd4": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 1
                                        },
                                        "/rewards": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 2
                                },
                                "occurences": 2
                            },
                            "occurences": 2
                        }
                    },
                    "leadsFrom": {
                        "path": {
                            "/": {
                                "occurences": 5
                            },
                            "/competition": {
                                "occurences": 1
                            },
                            "/explore": {
                                "occurences": 1
                            },
                            "/profile/*": {
                                "occurences": 1
                            }
                        }
                    },
                    "occurences": 32
                },
                {
                    "type": "mouse_click",
                    "nodes": [
                        "cb6594efa12b8e1e51128124be0a37d0"
                    ],
                    "name": "Unlock discount button",
                    "text": "UNLOCK DISCOUNT CODE",
                    "url": {
                        "type": "regex",
                        "value": "^/reviews/[a-zA-Z0-9_/?:.&!,%-]*$",
                        "valuePretty": "/reviews/*"
                    }
                }
            ],
            "issues": {
                "focus_lost": {
                    "type": "focus_lost",
                    "class": "behaviour"
                },
                "page_close": {
                    "type": "page_close",
                    "class": "behaviour"
                },
                "b33f156bad511667e158f60938be5bd4": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "FATAL: THIS SHOULD NOT BE LOADED WHEN NOT DEBUGGING!"
                    ]
                }
            }
        },
        {
            "id": "35b154818c25b5498b188bbeaac980ca0049a83a4985bdcd6768a04f06ec721b",
            "metadata": {
                "name": "Body shape quiz"
            },
            "flowPoints": [
                {
                    "type": "url_navigate",
                    "url": {
                        "type": "match",
                        "value": "/profile/quiz",
                        "valuePretty": "/profile/quiz"
                    },
                    "name": "User's profile page",
                    "issues": {
                        "focus_lost": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/register": {
                                            "occurences": 6
                                        },
                                        "/login": {
                                            "occurences": 1
                                        },
                                        "/": {
                                            "occurences": 9
                                        },
                                        "/rewards": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 17
                                },
                                "occurences": 23,
                                "flowpoint": {
                                    "occurences": 5
                                },
                                "external": {
                                    "occurences": 6
                                }
                            },
                            "occurences": 28
                        },
                        "64526a8a231320ca4ba813366f87ef9c": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 1
                                },
                                "occurences": 1
                            },
                            "occurences": 1
                        },
                        "page_close": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 3
                                        }
                                    },
                                    "occurences": 3
                                },
                                "occurences": 3,
                                "flowpoint": {
                                    "occurences": 2
                                }
                            },
                            "occurences": 5
                        },
                        "page_reload": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 1
                                },
                                "occurences": 1
                            },
                            "occurences": 1
                        },
                        "b33f156bad511667e158f60938be5bd4": {
                            "leadsTo": {
                                "internal": {
                                    "dest": {
                                        "/register": {
                                            "occurences": 2
                                        },
                                        "/": {
                                            "occurences": 2
                                        },
                                        "/rewards": {
                                            "occurences": 1
                                        }
                                    },
                                    "occurences": 5
                                },
                                "occurences": 5
                            },
                            "occurences": 5
                        }
                    },
                    "occurences": 36,
                    "leadsFrom": {
                        "path": {
                            "/register": {
                                "occurences": 14
                            },
                            "/explore": {
                                "occurences": 2
                            },
                            "/login": {
                                "occurences": 3
                            },
                            "/profile": {
                                "occurences": 3
                            }
                        }
                    },
                    "direct": {
                        "occurences": 4
                    }
                },
                {
                    "type": "mouse_click",
                    "nodes": [
                        "49a22d05eb0f23b80f23fc780e12cf55",
                        "8d59cc8ae93ab25a23ff78c7db682947"
                    ],
                    "name": "Confirm body shape",
                    "text": "Confirm",
                    "url": {
                        "type": "match",
                        "value": "/profile/quiz",
                        "valuePretty": "/profile/quiz"
                    },
                    "leadsTo": {
                        "internal": {
                            "dest": {
                                "/profile": {
                                    "occurences": 10
                                }
                            },
                            "occurences": 10
                        }
                    },
                    "occurences": 10,
                    "leadsFrom": {
                        "path": {
                            "/explore": {
                                "occurences": 2
                            },
                            "/profile": {
                                "occurences": 2
                            }
                        }
                    }
                }
            ],
            "issues": {
                "focus_lost": {
                    "type": "focus_lost",
                    "class": "behaviour"
                },
                "64526a8a231320ca4ba813366f87ef9c": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "You are overriding current access token, that means some other app is expecting different access token and you will probably break things. Please consider passing access_token directly to API parameters instead of overriding the global settings."
                    ]
                },
                "page_close": {
                    "type": "page_close",
                    "class": "behaviour"
                },
                "page_reload": {
                    "class": "behaviour",
                    "type": "page_reload"
                },
                "b33f156bad511667e158f60938be5bd4": {
                    "class": "technical",
                    "type": "console",
                    "subtype": "error",
                    "data": [
                        "FATAL: THIS SHOULD NOT BE LOADED WHEN NOT DEBUGGING!"
                    ]
                }
            }
        }
    ]