import "../utils/highlight"
import Script from "next/script"
import { DefaultSeo } from "next-seo"
import PropTypes from "prop-types"
import cookie from "cookie"
// next
import Head from "next/head"
import App from "next/app"
//
import { Provider as ReduxProvider } from "react-redux"
// @mui
// redux
import { store } from "../redux/store"
// utils
import { getSettings } from "../utils/settings"
// contexts
// theme
import ThemeProvider from "../theme"

// components
import RtlLayout from "../components/RtlLayout"
import ProgressBar from "../components/ProgressBar"
import ThemeColorPresets from "../components/ThemeColorPresets"
import NotistackProvider from "../components/NotistackProvider"
import MotionLazyContainer from "../components/animate/MotionLazyContainer"
import dynamic from "next/dynamic"
import styled from "styled-components"

import CookiePopup from "../components/CookiePopup"

import { IS_STAGING, IS_DEVELOPMENT, IS_PROD, FP_SCRIPT } from "../config"

const EnvContainer = styled.div`
    position: fixed;
    right: 10px;
    bottom: 0px;
    z-index: 9000;
    span {
        color: red;
    }
`

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from "../contexts/JWTContext"
// import { AuthProvider } from '../contexts/Auth0Context';
// import { AuthProvider } from '../contexts/FirebaseContext';
// import { AuthProvider } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

MyApp.propTypes = {
    Component: PropTypes.func,
    pageProps: PropTypes.object,
    settings: PropTypes.object,
}

const Intercom = dynamic(() => import("../utils/intercom"), {
    ssr: false,
})

export default function MyApp(props) {
    const { Component, pageProps, settings } = props

    const getLayout = Component.getLayout ?? ((page) => page)

    return (
        <>
            <DefaultSeo
                title='Flowpoint | AI-Powered Website Analytics to uncover why users drop off'
                description="Leverage AI to optimize website conversions, prioritize impactful solutions, and enhance ROI with data-driven decisions. Discover actionable insights to unleash your website's potential."
                openGraph={{
                    type: "website",
                    locale: "en_IE",
                    url: "https://flowpoint.ai",
                    site_name: "Flowpoint",
                    images: [
                        {
                            url: "https://flowpoint.ai/images/thumbnail.jpeg",
                            width: 1920,
                            height: 1080,
                            alt: "thumbnail",
                        },
                    ],
                }}
            />

            {IS_PROD && (
                <Script>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</Script>
            )}
            {IS_PROD && <Script async src='https://r.wdfl.co/rw.js' data-rewardful='337800'></Script>}

            <Script strategy='lazyOnload' id='flowpoint' src={FP_SCRIPT}></Script>

            <Script id='flowpoint-tracker' strategy='afterInteractive'>
                {`
       window.fpDataLayer = window.fpDataLayer || [];
       flowpoint = {
         disable: () => window.flowpointDisabled = true,
         enable: () => window.flowpointDisabled = false,
       }
       'track setUserAttributes'.split(' ').forEach(m => flowpoint[m] = (e, d) => window.fpDataLayer.push({ method: m, data: [e, d] }))
        `}
            </Script>
            <Script
                async
                src='https://tag.clearbitscripts.com/v1/pk_9a825bc4ec6dd6f3964855564dc39ab0/tags.js'
                referrerpolicy='strict-origin-when-cross-origin'
            />
            <Script id='facebook-pixel' strategy='lazyOnload'>
                {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1302106443707938');
          fbq('track', 'PageView');
        `}
            </Script>
            {IS_PROD && (
                <Script
                    id='google-tag-manager-load'
                    strategy='lazyOnload'
                    src='https://www.googletagmanager.com/gtag/js?id=AW-11014059734'
                ></Script>
            )}
            {IS_PROD && (
                <Script id='google-tag-manager' strategy='lazyOnload'>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11014059734');
          `}
                </Script>
            )}

            <Intercom></Intercom>

            <AuthProvider>
                <ReduxProvider store={store}>
                    <ThemeProvider>
                        <NotistackProvider>
                            <MotionLazyContainer>
                                <ThemeColorPresets>
                                    <RtlLayout>
                                        <ProgressBar />
                                        {getLayout(<Component {...pageProps} />, pageProps)}
                                        {IS_STAGING && (
                                            <EnvContainer>
                                                <span>Staging version</span>
                                            </EnvContainer>
                                        )}
                                        {IS_DEVELOPMENT && (
                                            <EnvContainer>
                                                <span>Development version</span>
                                            </EnvContainer>
                                        )}
                                    </RtlLayout>
                                    <CookiePopup />
                                </ThemeColorPresets>
                            </MotionLazyContainer>
                        </NotistackProvider>
                    </ThemeProvider>
                </ReduxProvider>
            </AuthProvider>
        </>
    )
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context) => {
    const appProps = await App.getInitialProps(context)

    const cookies = cookie.parse(context.ctx.req ? context.ctx.req.headers.cookie || "" : document.cookie)

    const settings = getSettings(cookies)

    return {
        ...appProps,
        settings,
    }
}
