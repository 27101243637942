import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useResponsive(query, key, start, end) {
  const theme = useTheme();
  const [windowLoaded, setWindowLoaded] = useState(false);

  // Define media queries
  const mediaUp = useMediaQuery(theme.breakpoints.up(key));
  const mediaDown = useMediaQuery(theme.breakpoints.down(key));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  useEffect(() => {
    function handleLoad() {
      setWindowLoaded(true);
    }

    // Set the listener for the window load event
    if(window) {
      setWindowLoaded(true)
    } else 
    window.addEventListener('load', handleLoad);

    // Clean up the listener
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  // Before window load, return null
  
  if (!windowLoaded) {
    return null;
  }

  // After window load, return the appropriate media query result
  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  if (query === 'only') {
    return mediaOnly;
  }
}

