// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = "/auth"
const ROOTS_DASHBOARD = "/dashboard"

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, "/login"),
    loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
    register: path(ROOTS_AUTH, "/register"),
    registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
    resetPassword: path(ROOTS_AUTH, "/reset-password"),
    verify: path(ROOTS_AUTH, "/verify"),
}

export const EXTERNAL_PATHS = {
    docs: "https://docs.flowpoint.ai",
    blog: "https://blog.flowpoint.ai",
    product_update: "https://blog.flowpoint.ai/post/compare-landing-page-and-cta-performance-with-flowpoint-variants/",
}

export const PATH_PAGE = {
    comingSoon: "/coming-soon",
    maintenance: "/maintenance",
    pricing: "/pricing",
    payment: "/payment",
    about: "/about-us",
    contact: "/contact-us",
    faqs: "/faqs",
    page404: "/404",
    page500: "/500",
    components: "/components",
    gdpr: "/GDPR-statement",
    changelog: "/changelog",
    freeTools: "/free-tools",
}

export const PATH_CASES = {
    dataAnalytics: "/data-analytics",
    aiMarketingAnalyst: "/ai-marketing-analyst",
    improveConversionRates: "/conversion-rate-optimisation",
    optimiseSEO: "/SEO",
    PPC: "/PPC",
    aiInsights: "/ai-insights",
    boostSales: "/e-commerce",
    dropoff: "/users-dropoff",
    monitoring: "/error-monitoring",
    b2c: "/case-studies/how-flowpoint-s-custom-event-tracking-improved-user-retention-during-the-onboarding-process-for-a-b2c-web-application",
    ecommerce: "/case-studies/how-flowpoint-identified-and-resolved-technical-and-ux-issues-for-an-ecommerce-business",
    serviceProvider:
        "/case-studies/how-flowpoints-cross-domain-tracking-capabilities-improved-conversion-tracking-and-decision-making-for-a-service-provider",
}

export const PATH_FEATURES = {
    aiRecommendations: "/p/f/ai-website-recommendations",
    websiteAnalysis: "/p/f/analyse-website-traffic",
    reports: "/p/f/simple-website-analytics-for-your-business",
    ava: "/p/f/website-analytics-ai-assistant",
    funnelAnalytics: "/p/f/website-funnel-analytics",
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, "/app"),
        ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
        analytics: path(ROOTS_DASHBOARD, "/analytics"),
        banking: path(ROOTS_DASHBOARD, "/banking"),
        booking: path(ROOTS_DASHBOARD, "/booking"),
    },
    mail: {
        root: path(ROOTS_DASHBOARD, "/mail"),
        all: path(ROOTS_DASHBOARD, "/mail/all"),
    },
    chat: {
        root: path(ROOTS_DASHBOARD, "/chat"),
    },
    calendar: path(ROOTS_DASHBOARD, "/calendar"),
    kanban: path(ROOTS_DASHBOARD, "/kanban"),
    user: {
        root: path(ROOTS_DASHBOARD, "/user"),
        profile: path(ROOTS_DASHBOARD, "/user/profile"),
        cards: path(ROOTS_DASHBOARD, "/user/cards"),
        list: path(ROOTS_DASHBOARD, "/user/list"),
        newUser: path(ROOTS_DASHBOARD, "/user/new"),
        editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
        account: path(ROOTS_DASHBOARD, "/user/account"),
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, "/e-commerce"),
        shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
        product: path(ROOTS_DASHBOARD, "/e-commerce/product/:name"),
        productById: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-air-force-1-ndestrukt"),
        list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
        newProduct: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
        editById: path(ROOTS_DASHBOARD, "/e-commerce/product/nike-blazer-low-77-vintage/edit"),
        checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
        invoice: path(ROOTS_DASHBOARD, "/e-commerce/invoice"),
    },
    blog: {
        root: path(ROOTS_DASHBOARD, "/blog"),
        posts: path(ROOTS_DASHBOARD, "/blog/posts"),
        post: path(ROOTS_DASHBOARD, "/blog/post/:title"),
        postById: path(ROOTS_DASHBOARD, "/blog/post/apply-these-7-secret-techniques-to-improve-event"),
        newPost: path(ROOTS_DASHBOARD, "/blog/new-post"),
    },
}

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction"
