import { createContext, useEffect, useReducer } from "react"
import PropTypes from "prop-types"
// utils
import axios from "../utils/axios"
import { isValidToken, setSession } from "../utils/jwt"

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
}

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        }
    },
    LOGIN: (state, action) => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user,
        }
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user,
        }
    },
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
    ...initialState,
    method: "jwt",
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
})

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
}

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const initialize = async () => {}

        initialize()
    }, [])

    const login = async (email, password) => {
        const response = await axios.post("/api/account/login", {
            email,
            password,
        })
        const { accessToken, user } = response.data

        setSession(accessToken)
        dispatch({
            type: "LOGIN",
            payload: {
                user,
            },
        })
    }

    const register = async (email, password, firstName, lastName) => {
        const response = await axios.post("/api/account/register", {
            email,
            password,
            firstName,
            lastName,
        })
        const { accessToken, user } = response.data

        window.localStorage.setItem("accessToken", accessToken)
        dispatch({
            type: "REGISTER",
            payload: {
                user,
            },
        })
    }

    const logout = async () => {
        setSession(null)
        dispatch({ type: "LOGOUT" })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
