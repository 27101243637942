import { useEffect, useState, useRef } from "react"
import * as React from "react"
import { Divider, Button, Popover } from "@mui/material"
import Iconify from "./Iconify"
import styled from "styled-components"
// import CookieConsent, { Cookies } from "react-cookie-consent";
// ----------------------------------------------------------------------

const CookieBox = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  min-height: 100px;
  max-height: fit-content;
  bottom: 0;
  right: 0;
  
  position: fixed;
  left: 0 !important;
  justify-content: center;
  z-index: 2147483005;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 32px 86px;
  align-content: center;
  justify-content: center;
  transition: "all 1s ease 0s;
    box-shadow:
      rgb(150 150 150 / 80%) 0px
      0px 2px,
    rgb(255 255 255 / 36%) 0px 4px 12px,
    rgb(237 237 237 / 36%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(6px);

  @media screen and (max-width: 1200px) {
    padding: 30px 20px 20px 30px;
  }
`
const CookieTextBox = styled.div`
    @media screen and (max-width: 1200px) {
        margin-bottom: 10px;
    }
`

const CookieText = styled.span`
    color: #0a2540;
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-weight: 400;
`

const cookieOptFlagName = "cookie_optIn"

export default function CookiePopup({ isManageCookie }) {
    const [open, setOpen] = useState(true)
    const [shouldDisplay, setDisplay] = useState(false)

    useEffect(() => {
        if (window) {
            const optInCookie = window.localStorage.getItem(cookieOptFlagName)
            const shouldShowBanner = isManageCookie || (optInCookie === null || optInCookie === undefined ? 1 : 0)
            // display cookie banner if there was never made a selection
            setDisplay(shouldShowBanner)
            // if there is a selection and it was opt out we have to keep things disabled
            if (optInCookie === "0") {
                // disable all tracking here
                setTimeout(() => {
                    disableServices()
                    deleteAllCookies()
                }, 2000)
            }
        }
    }, [])

    // function to opt int
    const optIn = () => {
        setOpen(false)
        window.localStorage.setItem(cookieOptFlagName, 1)
    }

    // function to opt out
    const optOut = () => {
        setOpen(false)
        window.localStorage.setItem(cookieOptFlagName, 0)
        deleteAllCookies()
        disableServices()
    }

    // function to disable current services
    const disableServices = () => {
        window.flowpointDisabled = true
        // todo add intercom and others
    }

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";")
        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=")
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            const newCookie = `${name} =; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/`
            document.cookie = newCookie
        }
    }

    return shouldDisplay ? (
        <>
            {open ? (
                <CookieBox>
                    <CookieTextBox style={{ maxWidth: "1000px", marginRight: "20px" }}>
                        <CookieText>
                            Flowpoint uses cookies to make it easier for you to use our website and analyse website usage.
                            <a target='_blank' style={{ paddingLeft: "3px" }} href='https://flowpoint.ai/cookie-notice/' rel='noreferrer'>
                                {" "}
                                Cookie Policy
                            </a>
                        </CookieText>
                    </CookieTextBox>
                    <div>
                        <Button
                            style={{
                                textDecoration: "none",
                                color: "white",
                                fontWeight: "500",
                                lineHeight: 1.7142857142857142,
                                fontSize: "0.875rem",
                                textTransform: "capitalize",
                                minWidth: "64px",
                                padding: "6px 16px",
                                borderRadius: "40px",
                                border: "1px solid",
                                marginRight: "15px",
                                background: "#0a2540",
                                borderColor: "#0a2540",
                            }}
                            onClick={() => optIn()}
                        >
                            Accept
                        </Button>
                        <Button
                            style={{
                                textDecoration: "none",
                                color: "white",
                                fontWeight: "500",
                                lineHeight: 1.7142857142857142,
                                fontSize: "0.875rem",
                                textTransform: "capitalize",
                                minWidth: "64px",
                                padding: "6px 16px",
                                borderRadius: "40px",
                                border: "1px solid",
                                marginRight: "15px",
                                background: "#0a2540",
                                borderColor: "#0a2540",
                            }}
                            onClick={() => optOut()}
                        >
                            Reject
                        </Button>
                    </div>
                    {/* <div
                        style={{
                            right: "17px",
                            top: "7px",
                            position: "absolute",
                            cursor: 'pointer'
                        }}
                    >
                        <Iconify icon={"eva:close-fill"} color='#5b62719e' width={20} height={20} onClick={() => optOut()} />
                    </div> */}
                </CookieBox>
            ) : (
                ""
            )}
        </>
    ) : (
        <div />
    )
}
